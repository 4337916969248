import { render, staticRenderFns } from "./GraficoNumeroDeCadastros.vue?vue&type=template&id=5d8cbe0c&scoped=true&"
import script from "./GraficoNumeroDeCadastros.vue?vue&type=script&lang=js&"
export * from "./GraficoNumeroDeCadastros.vue?vue&type=script&lang=js&"
import style0 from "./GraficoNumeroDeCadastros.vue?vue&type=style&index=0&id=5d8cbe0c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8cbe0c",
  null
  
)

export default component.exports