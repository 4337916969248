<template>
  <div
    id="IDturmasMaisExerciciosRealizados"
    class="shadow-sm border-default shadow-sm mt-3 pb-4"
  >
    <div class="col-md-12 mx-auto px-5 pb-2 pl-0">
      <h2 class="title-in-dados title-dados pb-2 mb-2 pt-5">
        {{ title ? title : "Novos Usuários Por Mês" }}
      </h2>
    </div>
    <div
      :class="`col-md-11 mx-auto pt-4 pb-3 px-0 ${bg_color} border-radius-7`"
    >
      <div class="row border-bottom pb-2 mx-2">
        <div class="col-md-2 pl-0">
          <h3>Turma</h3>
        </div>
        <div class="col-md-2">
          <h3>Número de Alunos</h3>
        </div>
        <div class="col-md-3">
          <h3>unidade</h3>
        </div>
        <div class="col-md-2 pl-0">
          <h3>Cadastrada em</h3>
        </div>
        <div class="col-md-3 pr-0 text-left">
          <h3>Exercícios Gerais Realizados</h3>
        </div>
      </div>
      <div class="row mt-4 px-4">
        <div class="col-md-2 text-left pl-0">
          <p>001 - 5 Série A</p>
        </div>
        <div class="col-md-2 text-left">
          <p>30</p>
        </div>
        <div class="col-md-3 text-left">
          <div class="d-flex cores-escola">
            <div class="color-1" :style="`background-color:${color_1}`"></div>
            <div
              class="color-2 ml-1"
              :style="`background-color:${color_2}`"
            ></div>
          </div>
          <p class="mt-2">Escola Estadual Dom Bosco</p>
        </div>
        <div class="col-md-2 text-left">
          <p>01/07/20</p>
        </div>
        <div class="col-md-3 text-left pr-0">
          <p>20% CONCLUÍDOS</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      color_1: "#F8E117",
      color_2: "#41E392",
    };
  },
  props: ["bg_color", "title"],
  mounted() {
    if (this.bg_color == "bg-222541") {
      $(
        "#IDturmasMaisExerciciosRealizados p, #IDturmasMaisExerciciosRealizados h3"
      ).css({
        color: "#fff",
      });
    }
  },
};
</script>

<style scoped>
.cores-escola > div {
  height: 10px;
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

h3 {
  font-size: 11px;
}

p {
  font-size: 10px;
}
</style>
