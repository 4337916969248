<template>
  <div id="IDGraficonumeroDeCadastros">
    <h2 class="title-in-dados title-dados pb-2 mb-2">Dados Gerais</h2>
    <div class="shadow-sm border-default shadow-sm px-5 py-3">
      <div class="pb-2">
        <div class="row">
          <div class="titles col-md-12 mx-2 px-0 row mb-3">
            <div class="col-md-6 pl-0">
              <h2 class="py-3 title-dados text-left">Número de Cadastros</h2>
            </div>
            <div class="col-md-3 pl-0">
              <h2 class="py-3 text-left">Total de Alunos Cadastrados</h2>
            </div>
            <div class="col-md-3 pl-0">
              <h2 class="py-3 text-left">Total de Alunos Cadastrados</h2>
            </div>
          </div>
          <div class="col-md-6 px-2">
            <div class="content bg-222541 py-4 px-4 border-radius-7">
              <div class="canva">
                <canvas
                  id="myChartDoughnutPie"
                  width="500"
                  height="500"
                ></canvas>
              </div>
            </div>
          </div>
          <div class="col-md-3 px-2">
            <div
              class="content border-transparent-x bg-222541 border-radius-7 status pt-4 position-relative"
            >
              <div class="position-absolute">
                <div class="text-center">
                  <h3 class="border-radius-7 color-fff bg-0e5daf">
                    {{ `+ ${DatasDashBoard.alunos_recentes} alunos recentes` }}
                  </h3>
                </div>
                <div class="text-center mt-3">
                  <h3 class="border-radius-7 color-fff bg-002f60">
                    {{
                      `+ ${DatasDashBoard.alunos_recentes} unidades recentes`
                    }}
                  </h3>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div>
                  <div class="d-flex j-c-center">
                    <div v-for="(item, index) in alunosTotaisShow" :key="index">
                      <div
                        class="number bg-0e5daf mx-1 border-radius-16"
                        v-if="item"
                      >
                        <h2 class="color-fff p-2">{{ item }}</h2>
                      </div>
                    </div>
                  </div>
                  <h4 class="color-fff text-capitalize text-center">
                    alunos totais
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 pr-0">
            <div
              class="content border-transparent-x bg-222541 border-radius-7 status pt-4 position-relative"
            >
              <div class="position-absolute">
                <div class="text-center">
                  <h3 class="border-radius-7 color-fff bg-0e5daf">
                    {{
                      `+ ${DatasDashBoard.professores_recentes} unidades recentes`
                    }}
                  </h3>
                </div>
                <div class="text-center mt-3">
                  <h3 class="border-radius-7 color-fff bg-002f60">
                    {{ `+ ${DatasDashBoard.temas_recentes} temas recentes` }}
                  </h3>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div>
                  <div class="d-flex j-c-center">
                    <div
                      v-for="(item, index) in professoresTotaisShow"
                      :key="index"
                      class="d-flex j-c-center"
                    >
                      <div
                        class="number bg-0e5daf mx-1 border-radius-16"
                        v-if="item"
                      >
                        <h2 class="color-fff p-2">{{ item }}</h2>
                      </div>
                    </div>
                  </div>
                  <h4 class="color-fff text-capitalize text-center">
                    professores totais
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      alunosTotaisShow: [],
      professoresTotaisShow: [],
    };
  },
  extends: Bar,
  mounted() {
    this.montaGrafico();
    this.preparaNumerosToShow();
    this.getDados();
    this.getTheUnidades();
  },
  computed: {
    ...mapGetters(["DatasDashBoard"]),
  },
  methods: {
    montaGrafico() {
      var ctx = document.getElementById("myChartDoughnutPie").getContext("2d");
      var options = {
        legend: {
          labels: {
            fontSize: 12,
            fontColor: "rgb(255, 255, 255)",
          },
        },
        tooltips: {
          enabled: true,
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let datasets = ctx.chart.data.datasets;

              if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                let sum = datasets[0].data.reduce((a, b) => a + b, 0);
                let percentage = Math.round((value / sum) * 100) + "%";
                return percentage;
              } else {
                return percentage;
              }
            },
            color: "#fff",
          },
        },
      };
      var myChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [
                this.DatasDashBoard.professores_totais,
                this.DatasDashBoard.alunos_totais,
                this.DatasDashBoard.unidades_totais,
              ],
              backgroundColor: [
                "rgba(255,159,64,1)",
                "rgba(255,99,132,1)",
                "rgba(255,205,86,1)",
              ],
              borderColor: ["rgb(255,255,255)"],
              borderWidth: 2,
              fill: true,
            },
          ],
          labels: ["Professores", "Alunos", "Instituições"],
        },
        options: options,
      });
    },
    preparaNumerosToShow(alunos, professores) {
      if (alunos >= 10) {
        `${alunos}`.split("").forEach((element1) => {
          this.alunosTotaisShow.push(element1);
        });
      } else {
        this.alunosTotaisShow.push(alunos);
      }
      if (professores >= 10) {
        `${professores}`.split("").forEach((element2) => {
          this.professoresTotaisShow.push(element2);
        });
      } else {
        this.professoresTotaisShow.push(professores);
      }
    },
    getDados() {
      this.$store
        .dispatch("getDatasDashBoard", this.$store.getters.getPerfilToken)
        .then((resolve) => {
          if (resolve.valid) {
            this.toastGlobal(
              "success",
              "Os dados do DashBoard foram carregados com sucesso"
            );

            this.preparaNumerosToShow(
              this.DatasDashBoard.alunos_totais,
              this.DatasDashBoard.professores_totais
            );
          } else {
            this.toastGlobal(
              "error",
              "Algo de errado ocorreu ao carregar os dados DashBoard"
            );
          }
        });
    },
    getTheUnidades() {
      this.$store.dispatch("getUnidadesDB", {
        token: this.$store.getters.getPerfilToken,
      });
    },
  },
};
</script>

<style scoped>
.border-radius-16 {
  border-radius: 5px;
}

.content {
  height: 500px;
}

.shadow-sm h2 {
  font-size: 12px;
}

h3 {
  font-size: 12px;
  font-weight: 500;
  padding: 7px 10px;
}

.status {
  display: flex;
  justify-content: center;
}

.number h2 {
  font-size: 28px;
}

h4 {
  font-size: 16px;
  margin-top: 35px;
}

.titles {
  border-bottom: 1px solid #e4e5e8;
}
</style>
